
import moment from 'moment';
import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMCalendar from "../../../components/common/TZMCalendar";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMDialog from "../../../components/dialog/TZMDialog";
import TZMPopup from "../../../components/dialog/TZMPopup";
import { ModalRealm } from '../../../components/modal/ModalRealm';
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import { Validator } from "../../../utilities/TZMValidator";
import { MetaService } from "../../servicos/MetaService";

export default class EditarMeta extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			meta: this.props.meta,
			visible: true,
			alterado: false
		};
		this.metaService = new MetaService();
		moment.locale('pt-BR');
	}

	handleUpdate = (key, event) => {
		let meta = this.state.meta;
		meta[key] = event.target.value;

		if (key === 'sas' || key === 'metaInter' || key === 'metaLocal') {
			meta.budget = Number(meta.sas) + Number(meta.metaInter) + Number(meta.metaLocal);
		}

		this.setState({ meta, alterado: true });
	}

	validarMeta = async () => {

		let messages = [];
		let meta = this.state.meta;

		if (Validator.isEmpty(meta.mes)) {
			messages.push("A data de referência do mês é obrigatória.");
		} 

		if (Validator.isEmpty(meta.metaLocal)) {
			messages.push("Meta Local é obrigatória.");
		}

		if (Validator.isEmpty(meta.metaInter)) {
			messages.push("Meta RRE/RRUY é obrigatória.");
		}		

		if (Validator.isEmpty(meta.sas)) {
			messages.push("Meta SAS é obrigatória.");
		}		

		if (messages.length > 0) {
			ModalRealm.showDialog(<TZMPopup header="Advertência" messages={messages} />);
			return false;
		}		
		return true;
	}

	salvar = () => {
		if (this.validarMeta()) {
			this.metaService.salvar(this.state.meta).then((response) => {
				this.setState({ visible: false });
				if (this.props.onSave) {
					this.props.onSave(response);
				}
			});
		}
	}

	fecharPainel = () => {
		this.setState({ visible: false });
		if (this.props.onClose) {
			this.props.onClose(this.state.meta);
		}
	}

	fechar = () => {
		if (this.state.alterado) {
			ModalRealm.showDialog(<TZMConfirm onYes={this.fecharPainel} question="Fechar e descartar as alterações feitas?" />);
		} else {
			this.fecharPainel();
		}
	}

	render() {
		return (
			<TZMDialog style={{ width: "600px" }} visible={this.state.visible} modal header="Meta" onHide={this.onHide} {...this.props}>
				<div className="ui-g">					
					<div className="ui-g-12">
						<TZMCalendar label="Mês" onChange={(event) => this.handleUpdate("mes", event)} value={moment(this.state.meta.mes).format("YYYY-MM")} />
					</div>					
					<div className="ui-g-4">
						<TZMTextField  min={0} type="number" onChange={(event) => this.handleUpdate("metaLocal", event)} label="Meta Local" value={this.state.meta.metaLocal} />
					</div>
					<div className="ui-g-4">
						<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("metaInter", event)} label="Meta RRE/RRUY" value={this.state.meta.metaInter} />
					</div>					
					<div className="ui-g-4">
						<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("sas", event)} label="Meta SAS" value={this.state.meta.sas} />
					</div>
					<div className="ui-g-12"/>
					<div className="ui-g-12">
					<TZMTextField min={0} type="number" onChange={(event) => this.handleUpdate("budget", event)} label="Budget" value={this.state.meta.budget} />
					</div>
					<div className="ui-g-12"/>
					<div className="ui-g-12">						
						<TZMTextField  min={0} type="number" onChange={(event) => this.handleUpdate("forecast", event)} label="Forecast" value={this.state.meta.forecast} />
					</div>
				</div>
				<TZMPanelFooter>					
					<TZMButton success={true} label="Salvar" icon="fas fa-save" onClick={this.salvar} />
					<TZMButton secondary={true} label="Fechar" icon="fas fa-times" onClick={this.fechar} />
				</TZMPanelFooter>

			</TZMDialog>
		);
	}
}
