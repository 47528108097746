import React from "react";
import TZMButton from "../../../components/common/TZMButton";
import TZMForm from "../../../components/common/TZMForm";
import TZMTextField from "../../../components/common/TZMTextField";
import TZMConfirm from "../../../components/dialog/TZMConfirm";
import TZMMainContent from "../../../components/main/TZMMainContent";
import { ModalRealm } from "../../../components/modal/ModalRealm";
import TZMPanel from "../../../components/panel/TZMPanel";
import TZMPanelFooter from "../../../components/panel/TZMPanelFooter";
import TZMColumn from "../../../components/table/TZMColumn";
import TZMTable from "../../../components/table/TZMTable";
import { Fetch } from "../../../utilities/Fetch";
import TZMShortcut from "../../../utilities/TZMShortcut";
import TZMUserData from "../../../utilities/TZMUserData";
import { PerfilService } from "../../servicos/PerfilService";
import EditarPerfil from "./EditarPerfil";

const title = "Perfis";
const empresaNome = process.env.REACT_APP_EMPRESA_NOME;

export class Perfil extends React.Component {

	state =  {
		list: [],
		params: {
			desativados: false,
			descricao: ''
		}
	};

	perfilService = new PerfilService();

	componentDidMount() {
		if (TZMUserData.isAutenticado()) {
			Fetch.Get("/ping");
		} else {
			window.location.replace("/");		
		}
	}

	handleList = async () => {
		await this.perfilService.buscar(`search=descricao~${this.state.params.descricao}${!this.state.desativados ? ";ativado:true" : ""}&sort=descricao`).then((list) => this.setState({list}));
	}
  
	handleUpdate = (event) => {
		let params = this.state.params;
		params[event.name] = event.target.value;
		this.setState({params});
	}

	handleHidden = () => {
		const params = this.state.params;
		params.desativados = !params.desativados;
		this.setState({params});
	}

	clonarPerfil = (perfil) => {
		perfil = Object.assign({}, perfil);
		perfil.id = null;
		perfil.descricao = `${perfil.descricao} (Cópia)`;
		this.editarPerfil(perfil);
	}

	columns = [
		<TZMColumn key={0} sortable field="descricao" style={{width: "*"}} header="Descrição" />,
		<TZMColumn key={1} header="Ações" style={{width: "5em", textAlign: "center"}} body={(perfil) => {
			return (
				<div>
					{TZMUserData.hasRole("____PERF_E") ? <span title="Editar Perfil" className="fa fa-edit ui-tzm-icon" onClick={() => this.editarPerfil(perfil)} /> : null}
					{TZMUserData.hasRole("____PERF_E") ? <span title="Clonar Perfil" className="fa fa-copy ui-tzm-icon" onClick={() => this.clonarPerfil(perfil)} /> : null}
					{TZMUserData.hasRole("____PERF_D") ? <span title="Editar Perfil" className="fa fa-trash-alt ui-tzm-icon" onClick={() => this.excluirPerfil(perfil)} /> : null}
				</div>
			);
		}} />
	];

	excluirPerfil = (perfil) => {
		ModalRealm.showDialog(<TZMConfirm onYes={() => {
			this.perfilService.excluir(perfil).then(this.handleList);
		}} question="Tem certeza de que deseja excluir este perfil?" />);
	}

	editarPerfil = (perfil) => {
		if (!perfil || perfil.id === undefined) {
			perfil = {
				descricao: "",
				roles: []
			};
		}
		ModalRealm.showDialog(<EditarPerfil key={Math.random()} perfil={perfil} onClose={this.handleList} onDelete={this.handleList} onSave={this.handleList} />);
	}

	render() {
		return (
			<TZMMainContent>
				<div className="ui-g">
					<div className="ui-g-12">
						<TZMPanel header={title}>
							<TZMForm>
								<div className="ui-g">E
									<div className="ui-g-12">
										<TZMTextField name="descricao" autoFocus onChange={this.handleUpdate} label="Descrição" value={this.state.params.descricao} />
									</div>
								</div>
							</TZMForm>
							<TZMPanelFooter>
								<TZMButton className="ui-button-secondary" title={this.state.params.desativados ? "Ocultar Desativados" : "Exibir Desativados"} style={{float: "left"}} onClick={this.handleHidden} icon={this.state.params.desativados ? "fas fa-eye": "fas fa-low-vision"} />
								{TZMUserData.hasRole("____PERF_C") ? <TZMButton className="ui-button-success ui-tzm-button" icon="fas fa-plus" label="Novo Perfil" onClick={this.editarPerfil} /> : null}
								<TZMButton className="ui-button-primary ui-tzm-button" icon="fas fa-search" label="Procurar" onClick={this.handleList} />
							</TZMPanelFooter>
						</TZMPanel>
						<div style={{height: "8px"}} />
						<TZMPanel header="Resultado da Pesquisa">
							<TZMTable value={this.state.list} paginator rows={50} children={this.columns} />
						</TZMPanel>
					</div>
				</div>
				<TZMShortcut active={TZMShortcut.modalCount === 0}
					onEnter={this.handleList}
					onCtrlH={this.handleHidden}
					onCtrlN={this.editarPerfil}
				/>			
				<span className="ui-tzm-dashboard-title">{empresaNome} - {title}</span>
				<ModalRealm />
			</TZMMainContent>
		);
	}

}
