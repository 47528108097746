export default class Roles {

    static ROLES_COMUM = [
        {label: "Perfis",                       value: ["____PERF_M", "____PERF_C", "____PERF_E", "____PERF_D"]},		
		{label: "Produto",                      value: ["____PROD_M", null        , "____PROD_E", "____PROD_D"]},
		{label: "Gestores",                     value: ["____GEST_M", "____GEST_C", "____GEST_E", "____GEST_D"]},
		{label: "Regionais",                    value: ["____REGN_M", "____REGN_C", "____REGN_E", "____REGN_D"]},
		{label: "Vendedores",                   value: ["____VEND_M", "____VEND_C", "____VEND_E", "____VEND_D"]},
		{label: "Usuários",                     value: ["____USER_M", "____USER_C", "____USER_E", "____USER_D"]},
        {label: "Budgets",                      value: ["____BUDG_M", "____BUDG_C", "____BUDG_E", "____BUDG_D"]},
		{label: "Clientes",                     value: ["____CLIE_M", "____CLIE_C", "____CLIE_E", "____CLIE_D"]},
        {label: "Lista de Preço",               value: ["____LSTP_M", "____LSTP_C", "____LSTP_E", "____LSTP_D"]},
        {label: "Lista de Preço Agendamento",   value: ["____LSTPA_M", "____LSTPA_C", "____LSTPA_E", "____LSTPA_D"]},
        {label: "Alçada",                       value: ["____ALCD_M", "____ALCD_C", "____ALCD_E", "____ALCD_D"]},
        {label: "Contas de E-mail",             value: ["____MAIL_M", "____MAIL_C", "____MAIL_E", "____MAIL_D"]},
        {label: "Gestão de Preços",             value: ["GER_AAP_DESC_M", "GER_AAP_DESC_C", "GER_AAP_DESC_E", "GER_AAP_DESC_D"]},
        {label: "Famílias",                     value: ["____FAMI_M", "____FAMI_C", "____FAMI_E", "____FAMI_D"]},
    ];

    static ROLES_AAP = [		
        {label: "Metas",                        value: ["____META_M", "____META_C", "____META_E", "____META_D"]},
		{label: "Painel CAC",                   value: ["CAC_PCAC_M"]},
        {label: "Pedidos",                      value: ["CAC_PEDD_M", "CAC_PEDD_C", "CAC_PEDD_E", null ]},
        {label: "Inadimplência",                value: ["GER_AAP_INAD_M"]},
        {label: "Painel Gerencial",             value: ["GER_AAP_PGER_M"]},        
        {label: "Análise de Vendas",            value: ["GER_ANA_M"]},        
        {label: "Acompanhamento Diário",        value: ["PCP_ACPD_M"]},        
        {label: "Lantek",                       value: ["PCP_LTEK_M"]},
        {label: "Acompanhamento Carteira",      value: ["REL_ACAR_M"]}
    ];
    
}
